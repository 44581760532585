<template>
  <div class="tagVideo">
    <Header :title="tagName" />
    <!-- tab -->
    <van-tabs
      v-model="active"
      class="homeTab"
      title-active-color="rgb(0,0,0)"
      title-inactive-color="rgb(142,142,142)"
      color="#ff0d2c"
      animated
      swipeable
      :lazy-render="false"
    >
      <van-tab :title="item.label" v-for="item in tabList" :key="item.value">
        <VideoList :tagId="tagId" :type="item.value" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import Header from '@/components/Header';
import VideoList from './videoList/index.vue';

export default {
  name: 'tagVideo',
  components: {
    Header,
    VideoList,
  },
  data() {
    return {
      tagId: '',
      tagName: '',
      active: 0,
      tabList: [
        {
          label: '长视频',
          value: 'MOVIE',
        },
        {
          label: '短视频',
          value: 'SP',
        },
      ], // tab列表
    };
  },
  created() {
    this.tagId = this.$route.query.id;
    this.tagName = this.$route.query.name;
    this.active = this.$route.query.type == 'SP' ? 1 : 0;
  },
};
</script>
<style lang="scss" scoped>
.tagVideo {
  height: 100%;
}
/deep/ .van-tabs {
  .van-tabs__wrap {
    padding: 0 20px;
    width: 140px;
  }
  .van-tab__text {
    font-size: 14px;
  }
  .van-tab--active {
    font-weight: 600;
  }
  .van-tabs__line {
    width: 22px;
    height: 8px;
    background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
    bottom: 20px;
  }
}
</style>
