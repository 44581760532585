<template>
  <div class="videoList overflow-y-auto">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="slideBox" :class="{ movie: type === 'MOVIE' }">
        <VideoBox @click.native="openVideo(item)" class="slideItem" :videoInfo="item" v-for="item in list" :key="item.id" />
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { queryTagList } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import VideoBox from '@/components/VideoBox';

export default {
  name: 'tagVideo',
  components: {
    PullRefresh,
    VideoBox,
  },
  props: {
    tagId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        tagID: this.tagId,
        newsType: this.type,
      };
      try {
        let res = await this.$Api(queryTagList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
            fromTagVideo: 1,
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push({path: '/shortVideoDetails', query: {fromTagVideo: 1,}});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 246px;
  width: 164px;
}
.movie {
  /deep/ .videoBox .coverBox {
    height: 92px;
    width: 164px;
  }
}
.videoList {
  height: calc(100vh - 98px);
  padding: 10px 20px 0;
  .slideBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .slideItem {
      width: 164px;
    }
  }
}
</style>
